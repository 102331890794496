import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { fetchCategories } from "../../services/categoryService";
import { fetchProducts } from "../../services/productService";
import { fetchFeatureNames } from "../../services/featureNameService";

const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = new Date(targetDate) - now;
  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    return null;
  }
};

const AdminDashboard = () => {
  const [totalCategories, setTotalCategories] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalFeatures, setTotalFeatures] = useState(0);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [features, setFeatures] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const [domainCountdown, setDomainCountdown] = useState({});
  const [hostingCountdown, setHostingCountdown] = useState({});

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const [categoriesData, productsData, featuresData] = await Promise.all([
          fetchCategories(),
          fetchProducts(),
          fetchFeatureNames(),
        ]);

        setTotalCategories(categoriesData.length);
        setTotalProducts(productsData.length);
        setTotalFeatures(featuresData.length);

        setCategories(categoriesData);
        setProducts(productsData);
        setFeatures(featuresData);
      } catch (err) {
        console.error("Veriler yüklenirken hata oluştu:", err);
      }
    };

    loadDashboardData();
  }, []);

  useEffect(() => {
    const updateCountdowns = () => {
      setDomainCountdown(calculateTimeLeft("2025-04-09"));
      setHostingCountdown(calculateTimeLeft("2025-12-30"));
    };

    updateCountdowns();
    const interval = setInterval(updateCountdowns, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderCountdownCard = (title, countdown) => {
    if (!countdown) {
      return (
        <Card sx={{ padding: "20px", textAlign: "center" }}>
          <Typography variant="h6" color="error">
            {title} geçti!
          </Typography>
        </Card>
      );
    }

    const { days, hours, minutes, seconds } = countdown;

    return (
      <Card sx={{ padding: "20px", textAlign: "center" }}>
        <CardContent>
          <Typography variant="h5" sx={{ marginBottom: "20px" }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <Box sx={{ textAlign: "center", padding: "10px", minWidth: "60px" }}>
              <Typography variant="h4">{days}</Typography>
              <Typography variant="body2">Gün</Typography>
            </Box>
            <Box sx={{ textAlign: "center", padding: "10px", minWidth: "60px" }}>
              <Typography variant="h4">{hours}</Typography>
              <Typography variant="body2">Saat</Typography>
            </Box>
            <Box sx={{ textAlign: "center", padding: "10px", minWidth: "60px" }}>
              <Typography variant="h4">{minutes}</Typography>
              <Typography variant="body2">Dakika</Typography>
            </Box>
            <Box sx={{ textAlign: "center", padding: "10px", minWidth: "60px" }}>
              <Typography variant="h4">{seconds}</Typography>
              <Typography variant="body2">Saniye</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderList = (title, data, total, showAll, setShowAll) => (
    <Paper sx={{ padding: "20px", textAlign: "center" }}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h3">{total}</Typography>
      <List>
        {data.slice(0, showAll ? data.length : 5).map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      {data.length > 5 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? "Daha Az Göster" : "Daha Fazla Göster"}
        </Button>
      )}
    </Paper>
  );

  return (
    <Container maxWidth="lg" sx={{ marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Yönetim Paneli
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderCountdownCard("Alan Adı Kullanımının Bitişine Kalan", domainCountdown)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderCountdownCard("Hosting Kullanımının Bitişine Kalan", hostingCountdown)}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderList(
            "Toplam Ürün",
            products,
            totalProducts,
            showAllProducts,
            setShowAllProducts
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderList(
            "Kategori Sayısı",
            categories,
            totalCategories,
            showAllCategories,
            setShowAllCategories
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderList(
            "Özellik Sayısı",
            features,
            totalFeatures,
            showAllFeatures,
            setShowAllFeatures
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
