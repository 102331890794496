import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addProduct } from "../../services/productService";
import { fetchCategories } from "../../services/categoryService";

const AdminProductAdd = () => {
  const [formData, setFormData] = useState({
    name: "",
    categoryId: "",
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Kategorileri yüklemek için useEffect
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (err) {
        console.error("Kategoriler yüklenirken bir hata oluştu:", err);
        setError("Kategoriler yüklenemedi.");
      }
    };

    loadCategories();
  }, []);

  // Form alanındaki değişiklikleri işleme
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form gönderim işlemi
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const payload = {
        name: formData.name,
        category: {
          categoryId: formData.categoryId,
        },
      };

      const response = await addProduct(payload);

      if (response) {
        navigate("/admin/products"); // Ürünler listesine yönlendirme
      }
    } catch (err) {
      setError("Ürün eklenirken bir hata oluştu.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "30px" }}>
      <Typography variant="h4" gutterBottom>
        Ürün Ekle
      </Typography>
      {error && (
        <Typography variant="body1" color="error" sx={{ marginBottom: "15px" }}>
          {error}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Ürün Adı"
          name="name"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Kategori</InputLabel>
          <Select
            name="categoryId"
            value={formData.categoryId}
            onChange={handleChange}
            required
          >
            {categories.map((category) => (
              <MenuItem key={category.categoryId} value={category.categoryId}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: "15px" }}
          disabled={loading}
        >
          {loading ? "Ekleniyor..." : "Ürünü Ekle"}
        </Button>
      </Box>
    </Container>
  );
};

export default AdminProductAdd;
