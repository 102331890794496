import axios from 'axios';

const API_BASE_URL = 'https://www.arancaraskal.com/api';

// Tüm Ürünleri Getir
export const fetchProducts = async () => {
    const response = await axios.get(`${API_BASE_URL}/Product`);
    return response.data; // Backend'in direkt liste döndüğünü varsayıyorum
};

// ID'ye Göre Ürün Getir
export const fetchProductById = async (productId) => {
    const response = await axios.get(`${API_BASE_URL}/Product/${productId}`);

    return response.data;
};

// Yeni Ürün Ekle
export const addProduct = async (product) => {
    const response = await axios.post(`${API_BASE_URL}/Product`, product, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};

// Ürün Güncelle
export const updateProduct = async (productId, updatedProduct) => {
    const payload = {
        productId: parseInt(productId, 10),
        name: updatedProduct.name,
        category: {
            categoryId: parseInt(updatedProduct.categoryId, 10),
        },
        description: updatedProduct.description || null,
        price: updatedProduct.price ? parseFloat(updatedProduct.price) : null,
    };

    const response = await axios.put(`${API_BASE_URL}/Product/${productId}`, payload, {
        headers: { 'Content-Type': 'application/json' },
    });

    return response.data;
};


// Ürün Sil
export const deleteProduct = async (productId) => {
    const response = await axios.delete(`${API_BASE_URL}/Product/${productId}`);
    return response.data;
};

// Kategoriye Göre Ürünleri Getir
export const fetchProductsByCategory = async (categoryId) => {
    const response = await axios.get(`${API_BASE_URL}/Product/category/${categoryId}`);
    return response.data;
};

// Ürün Fotoğrafı Ekle
export const addProductPhoto = async (productId, photoFile) => {
    // FormData oluşturma
    const formData = new FormData();
    formData.append("photoFile", photoFile);

    // API'ye istek gönderme
    const response = await axios.put(`${API_BASE_URL}/Product/${productId}/photo`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response.data;
};

// Ürün Fotoğrafını Sil
export const removeProductPhoto = async (productId, photoUrl) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/Product/${productId}/photo`, {
            data: photoUrl, // Backend `photoUrl`'u body'de string olarak bekliyor
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data; // Silme işlemi başarılıysa dönen mesaj
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('Fotoğraf bulunamadı.');
        }
        throw new Error('Bir hata oluştu.');
    }
};

// Ürünleri Yeniden Sırala
export const reorderProducts = async (orderedProductIds) => {
    const response = await axios.post(`${API_BASE_URL}/Product/reorder`, orderedProductIds, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};
