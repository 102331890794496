import axios from 'axios';

const API_BASE_URL = 'https://www.arancaraskal.com/api';

// Tüm Özellik İsimlerini Getir
export const fetchFeatureNames = async () => {
    const response = await axios.get(`${API_BASE_URL}/FeatureName`);
    return response.data; // Backend'in direkt liste döndüğünü varsayıyorum
};

// Yeni Özellik İsmi Ekle
export const addFeatureName = async (featureName) => {
    const response = await axios.post(`${API_BASE_URL}/FeatureName`, featureName, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};

// Özellik İsmini Sil
export const deleteFeatureName = async (featureNameId) => {
    const response = await axios.delete(`${API_BASE_URL}/FeatureName/${featureNameId}`);
    return response.data;
};
