import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchProductById,
  updateProduct,
  addProductPhoto,
  removeProductPhoto,
} from "../../services/productService";
import { addProductFeature } from "../../services/productFeatureService";
import { fetchFeatureNames } from "../../services/featureNameService";
import { fetchCategories } from "../../services/categoryService";
import { deleteProductFeature } from "../../services/productFeatureService";

const AdminProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [featureNames, setFeatureNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newFeature, setNewFeature] = useState({
    featureNameId: "",
    value: "",
  });
  const [file, setFile] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({
    name: "",
    categoryId: "",
    description: "",
    price: "",
  });

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const [productData, featureNamesData, categoriesData] =
          await Promise.all([
            fetchProductById(productId),
            fetchFeatureNames(),
            fetchCategories(),
          ]);
        setProduct(productData);
        setFeatureNames(featureNamesData);
        setCategories(categoriesData);
      } catch (err) {
        setError("Veriler yüklenirken bir hata oluştu.");
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [productId]);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleFeatureChange = (e) => {
    const { name, value } = e.target;
    setNewFeature({ ...newFeature, [name]: value });
  };

  const handleAddFeature = async () => {
    try {
      const featureData = {
        productId: parseInt(productId, 10),
        featureNameId: newFeature.featureNameId,
        value: newFeature.value,
      };
      await addProductFeature(featureData);
      alert("Özellik başarıyla eklendi.");
      setIsDialogOpen(false);
      const updatedProduct = await fetchProductById(productId);
      setProduct(updatedProduct);
    } catch (err) {
      alert("Özellik eklenirken bir hata oluştu.");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadPhoto = async () => {
    if (!file) {
      alert("Lütfen bir fotoğraf seçin.");
      return;
    }

    try {
      await addProductPhoto(productId, file);
      alert("Fotoğraf başarıyla yüklendi.");
      const updatedProduct = await fetchProductById(productId);
      setProduct(updatedProduct);
    } catch (err) {
      alert("Fotoğraf yüklenirken bir hata oluştu.");
    }
  };

  const handleDeletePhoto = async (photoUrl) => {
    try {
      await removeProductPhoto(productId, photoUrl);
      alert("Fotoğraf başarıyla silindi.");
      const updatedProduct = await fetchProductById(productId);
      setProduct(updatedProduct);
    } catch (err) {
      alert("Fotoğraf silinirken bir hata oluştu.");
    }
  };

  const handleUpdateProduct = async () => {
    try {
      const payload = {
        name: updatedProduct.name,
        categoryId: parseInt(updatedProduct.categoryId, 10),
        description: updatedProduct.description || null,
        price: updatedProduct.price ? parseFloat(updatedProduct.price) : null,
      };
      await updateProduct(productId, payload);
      alert("Ürün başarıyla güncellendi.");
      const refreshedProduct = await fetchProductById(productId);
      setProduct(refreshedProduct);
      setIsEditDialogOpen(false);
    } catch (err) {
      alert("Ürün güncellenirken bir hata oluştu.");
      console.error(err);
    }
  };

  const handleEditDialogOpen = () => {
    setUpdatedProduct({
      name: product.name,
      categoryId: product.category.categoryId,
      description: product.description || "",
      price: product.price || "",
    });
    setIsEditDialogOpen(true);
  };

  const handleDeleteFeature = async (productFeatureId) => {
    if (window.confirm("Bu özelliği silmek istediğinize emin misiniz?")) {
      try {
        await deleteProductFeature(productFeatureId);
        alert("Özellik başarıyla silindi.");

        // Özelliği listeden çıkar
        setProduct((prevProduct) => ({
          ...prevProduct,
          features: prevProduct.features.filter(
            (feature) => feature.productFeatureId !== productFeatureId
          ),
        }));
      } catch (err) {
        console.error("Özellik silinirken hata oluştu:", err);
        alert("Özellik silinirken bir hata oluştu.");
      }
    }
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct((prev) => ({ ...prev, [name]: value }));
  };

  if (isLoading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    product && (
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Ürün Detayları
        </Typography>
        <Typography variant="h6">Adı: {product.name}</Typography>
        <Typography>Kategori: {product.category.name}</Typography>
        <Typography>
          Fiyat: {product.price ? `${product.price} TL` : "Belirtilmemiş"}
        </Typography>
        <Typography>
          Açıklama: {product.description || "Açıklama mevcut değil."}
        </Typography>

        {/* Fotoğraf Yükleme Butonu */}
        <div>
          <input type="file" onChange={handleFileChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadPhoto}
            sx={{ marginTop: "20px" }}
          >
            Fotoğraf Yükle
          </Button>
        </div>

        {/* Ürün Fotoğrafları */}
        <Typography variant="h5" sx={{ marginTop: "30px" }}>
          Ürün Fotoğrafları
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {product.photos.map((photo, index) => (
            <Grid item key={index} xs={4} style={{ position: "relative" }}>
              <img
                src={photo.url}
                alt={`Ürün Fotoğrafı ${index + 1}`}
                style={{ width: "100%", height: "auto" }}
              />
              <IconButton
                onClick={() => handleDeletePhoto(photo.url)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: "red",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
          sx={{ marginTop: "20px" }}
        >
          Yeni Özellik Ekle
        </Button>

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Yeni Özellik Ekle</DialogTitle>
          <DialogContent>
            <Select
              fullWidth
              name="featureNameId"
              value={newFeature.featureNameId}
              onChange={handleFeatureChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Özellik Seçin
              </MenuItem>
              {featureNames.map((feature) => (
                <MenuItem
                  key={feature.featureNameId}
                  value={feature.featureNameId}
                >
                  {feature.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              margin="normal"
              name="value"
              label="Değer"
              value={newFeature.value}
              onChange={handleFeatureChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              İptal
            </Button>
            <Button onClick={handleAddFeature} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          variant="contained"
          color="primary"
          onClick={handleEditDialogOpen}
          sx={{
            marginTop: "20px",
            marginLeft: "20px",
            backgroundColor: "purple",
          }}
        >
          Ürünü Güncelle
        </Button>

        <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Ürün Güncelle</DialogTitle>
          <DialogContent>
            {/* Ürün Adı */}
            <TextField
              fullWidth
              margin="dense"
              label="Ürün Adı"
              name="name"
              value={updatedProduct.name}
              onChange={(e) =>
                setUpdatedProduct({ ...updatedProduct, name: e.target.value })
              }
              required
            />

            {/* Kategori Seçimi */}
            <Select
              fullWidth
              value={updatedProduct.categoryId || ""}
              onChange={(e) =>
                setUpdatedProduct({
                  ...updatedProduct,
                  categoryId: e.target.value,
                })
              }
              required
              displayEmpty
              sx={{ marginTop: 2, marginBottom: 2 }}
            >
              <MenuItem value="" disabled>
                Kategori Seçin
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.categoryId} value={category.categoryId}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>

            {/* Açıklama */}
            <TextField
              fullWidth
              margin="dense"
              label="Açıklama"
              name="description"
              value={updatedProduct.description || ""}
              onChange={(e) =>
                setUpdatedProduct({
                  ...updatedProduct,
                  description: e.target.value,
                })
              }
            />

            {/* Fiyat */}
            <TextField
              fullWidth
              margin="dense"
              label="Fiyat"
              name="price"
              type="number"
              value={updatedProduct.price || ""}
              onChange={(e) =>
                setUpdatedProduct({ ...updatedProduct, price: e.target.value })
              }
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleEditDialogClose} color="secondary">
              İptal
            </Button>
            <Button
              onClick={handleUpdateProduct}
              color="primary"
              disabled={!updatedProduct.name || !updatedProduct.categoryId}
            >
              Güncelle
            </Button>
          </DialogActions>
        </Dialog>

        <Typography variant="h5" sx={{ marginTop: "30px" }}>
          Ürün Özellikleri
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Özellik
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Değer
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  İşlemler
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.features.map((feature) => (
                <TableRow key={feature.productFeatureId}>
                  <TableCell>{feature.featureName.name}</TableCell>
                  <TableCell>{feature.value}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleDeleteFeature(feature.productFeatureId)
                      }
                    >
                      Sil
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    )
  );
};

export default AdminProductDetail;
