import axios from 'axios';

const API_BASE_URL = 'https://www.arancaraskal.com/api';

// Tüm Ürün Özelliklerini Getir
export const fetchProductFeatures = async () => {
    const response = await axios.get(`${API_BASE_URL}/ProductFeature`);
    return response.data; // Backend'in direkt liste döndüğünü varsayıyorum
};

// Belirli Ürüne Ait Özellikleri Getir
export const fetchProductFeaturesByProductId = async (productId) => {
    const response = await axios.get(`${API_BASE_URL}/ProductFeature/product/${productId}`);
    return response.data;
};

// Yeni Ürün Özelliği Ekle
export const addProductFeature = async (productFeature) => {
    const { productId, featureNameId, value } = productFeature;
    const response = await axios.post(
        `${API_BASE_URL}/ProductFeature`,
        null, // POST isteği ama body gerekmiyor
        {
            params: {
                productId,
                featureNameId,
                value,
            },
        }
    );
    return response.data;
};



// Ürün Özelliğini Sil
export const deleteProductFeature = async (productFeatureId) => {
    const response = await axios.delete(`${API_BASE_URL}/ProductFeature/${productFeatureId}`);
    return response.data;
};
