import React, { useState } from "react";
import { Container, TextField, Button, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addFeatureName } from "../../services/featureNameService";

const AdminFeatureNameAdd = () => {
  const [featureName, setFeatureName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await addFeatureName({ name: featureName });
      setSuccessMessage("Özellik ismi başarıyla eklendi.");
      setFeatureName("");
      setErrorMessage("");

      // Başarılı ekleme sonrası yönlendirme
      setTimeout(() => navigate("/admin/features"), 1500);
    } catch (error) {
      setErrorMessage("Özellik ismi eklenirken bir hata oluştu.");
      setSuccessMessage("");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Yeni Özellik İsmi Ekle
      </Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <TextField
        label="Özellik İsmi"
        variant="outlined"
        fullWidth
        value={featureName}
        onChange={(e) => setFeatureName(e.target.value)}
        sx={{ marginBottom: "20px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!featureName.trim()}
      >
        Özellik İsmi Ekle
      </Button>
    </Container>
  );
};

export default AdminFeatureNameAdd;
