import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchFeatureNames, deleteFeatureName } from '../../services/featureNameService';

const AdminFeatureNameList = () => {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getFeatures = async () => {
    try {
      setLoading(true);
      const featureData = await fetchFeatureNames();
      setFeatures(featureData);
    } catch (err) {
      setError('Özellikler yüklenirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (featureNameId) => {
    if (window.confirm('Bu özelliği silmek istediğinize emin misiniz?')) {
      try {
        await deleteFeatureName(featureNameId);
        setFeatures((prevFeatures) =>
          prevFeatures.filter((feature) => feature.featureNameId !== featureNameId)
        );
      } catch (err) {
        setError('Özellik silinirken bir hata oluştu.');
      }
    }
  };

  useEffect(() => {
    getFeatures();
  }, []);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Özellikler Listesi
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Özellik ID</strong></TableCell>
              <TableCell><strong>Özellik Adı</strong></TableCell>
              <TableCell><strong>İşlemler</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {features.map((feature) => (
              <TableRow key={feature.featureNameId}>
                <TableCell>{feature.featureNameId}</TableCell>
                <TableCell>{feature.name}</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(feature.featureNameId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminFeatureNameList;
