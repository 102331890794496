import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from '../services/categoryService';
import '../styles/CategoryBar.css';

const CategoryBar = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategories(data);
      } catch (err) {
        console.error('Kategoriler yüklenirken bir hata oluştu:', err);
      }
    };

    loadCategories();
  }, []);

  const handleCategorySearch = (categoryId, categoryName) => {
    navigate('/urunler', { state: { categoryId, categoryName } });
  };

  return (
    <Box className="category-bar">
      {categories.map((category) => (
        <button
          key={category.categoryId}
          className="category-item"
          onClick={() => handleCategorySearch(category.categoryId, category.name)}
        >
          <img
            className="category-image"
            src={category.categoryPhotoUrl}
            alt={category.name}
          />
          <span className="category-name">{category.name}</span>
        </button>
      ))}
    </Box>
  );
};

export default CategoryBar;
