import axios from 'axios';

const API_BASE_URL = 'https://www.arancaraskal.com/api';

// Kategorileri Getir
export const fetchCategories = async () => {
    const response = await axios.get(`${API_BASE_URL}/Category`);
    return response.data; // Backend'in direkt liste döndüğünü varsayıyorum
};

// Yeni Kategori Ekle
export const addCategory = async (category) => {
    const response = await axios.post(`${API_BASE_URL}/Category`, category, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};

// Kategori Güncelle
export const updateCategory = async (categoryId, updatedCategory) => {
    const response = await axios.put(`${API_BASE_URL}/Category/${categoryId}`, updatedCategory, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};

// Kategori Sil
export const deleteCategory = async (categoryId) => {
    const response = await axios.delete(`${API_BASE_URL}/Category/${categoryId}`);
    return response.data;
};

export const reorderCategories = async (orderedCategoryIds) => {
    const response = await axios.put(`${API_BASE_URL}/Category/reorder`, orderedCategoryIds, {
        headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
};


// Kategori Fotoğrafı Yükle
export const uploadCategoryPhoto = async (categoryId, photoFile) => {
    // FormData oluşturma
    const formData = new FormData();
    formData.append("photoFile", photoFile);

    // API'ye istek gönderme
    const response = await axios.put(`${API_BASE_URL}/Category/${categoryId}/photo`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response.data;
};