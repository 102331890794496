import React, { useEffect, useState } from 'react';
import AdminPanelSidebar from './AdminPanelSidebar';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const AdminPanel = ({ children }) => {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAdminAuthenticated');
    setAuth(isAuthenticated);
  }, []);
  const navigate = useNavigate();

  if (!auth || auth == null) {
    navigate('/');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminPanelSidebar />
      <Outlet /> 
    </Box>
  );
};

export default AdminPanel;
